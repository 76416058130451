// 
// badge.scss
//

.badge {
    color: $white;
    font-family: $font-family-secondary;
}

.badge-light {
    color: $gray-800;
}

.badge-light{
    color: $white;
    background-color: lighten($gray-100, 4%) !important;
    border-color:   lighten($gray-100, 4%) !important;
  }

  .badge-dark{
    color: $light !important;
  }



// badge lighten

@mixin badge-light-variant($bg) {
    background-color: rgba($bg, 0.2);
    color: $bg !important;
}

@each $color, $value in $theme-colors {
    .badge-light-#{$color} {
      @include badge-light-variant($value);
    }
}